import { trackClarityTag } from '@/composables/tracking/useTracking';
import useApiHelpers from '../api/helpers';

const getRandomNumber = (max = 100) => Math.floor(Math.random() * max) + 1;

const pushToDataLayer = (options) => {
  if (!options.name || !options.version || !options.segment) {
    console.error('Error: Must provide all options to pushToDataLayer');
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'banxa-split-test',
    featureName: options.name,
    featureVersion: options.version,
    cohort: options.segment,
  });
};

const checkExistingCohort = (testName, version = 1) => {
  const existing = localStorage.getItem(testName);

  if (!existing) {
    return null;
  }

  pushToDataLayer({ name: testName, segment: existing, version });

  return existing;
};

const assignCohort = (testName, percentageOfUsers = 50, version = 1) => {
  const value = getRandomNumber() <= percentageOfUsers ? 'a' : 'b';

  const segmentOptions = {
    name: testName,
    segment: value,
    version,
  };

  localStorage.setItem(testName, value);

  pushToDataLayer(segmentOptions);

  return value;
};

const getFeatureFlags = () => {
  const { getRequest } = useApiHelpers();

  return getRequest('/getFeatureFlags');
};

const getFeatureFlag = async (flag) => {
  const { getRequest } = useApiHelpers();

  let flagPresent = await getRequest(`/getFeatureFlag/${flag}`);

  flagPresent = flagPresent.data;

  if (Object.keys(flagPresent).length !== 0) {
    const percentage = Object.values(flagPresent)[0] ?? 100;
    flagPresent = assignCohort(flag, percentage, 1);
  } else {
    flagPresent = assignCohort(flag, 0, 1);
  }

  return flagPresent;
};

const featureFlag = async (flag) => {
  const flagPresent = checkExistingCohort(flag) ?? await getFeatureFlag(flag);

  trackClarityTag(flag, flagPresent === 'a');

  return flagPresent === 'a';
};

export {
  getRandomNumber,
  assignCohort,
  checkExistingCohort,
  featureFlag,
};
