import { useOrderFormStore } from '@/stores/forms/orderFormStore';

import useModalActions from '@/composables/store/modal/useModalActions';
import useGlobalRouterActions from '@/composables/store/global/useGlobalRouterActions';
import useProgressBarActions from '@/composables/progress/useProgressBarActions';
import { useIdentityStore } from '@/stores/identityStore';
import { useLegacyIdentity } from '@/composables/store/identity/constants';

const { showSuccessModal } = useModalActions();
const { useLoadingStateAction } = useGlobalRouterActions();
const { updateToComplete } = useProgressBarActions();
let hasHitBackButton = false;

export default function useBeforeEach() {
  const handleSuccessModal = (to, from, next) => {
    if (to.path.includes('error')) {
      return;
    }

    if (
      from.path.includes('/verification/code')
      && !from.path.includes('/identity') // handle back event
      && !to.path.includes('verification') // handle back event
    ) {
      showSuccessModal({ progressLevel: 'Verification' });
    } else if (
      from.path === '/verification'
      && to.path.includes('identity')
    ) {
      updateToComplete(['verification']);
      showSuccessModal({ progressLevel: 'Verification' });
    } else if (
      from.path.includes('identity')
      && !to.path.includes('identity')
      && to.path.includes('/payment')
      && !to.path.includes('/verification/code') // handle back event
    ) {
      showSuccessModal({ progressLevel: 'Identity' });
    } else if (from.path.includes('/payment') && to.path.includes('/status')) {
      showSuccessModal({ progressLevel: 'Payment' });
    }
  };

  const handleIdentityKycPathings = async (to, from, next) => {
    if (!to.path.includes('identity') && !from.path.includes('identity')) {
      return;
    }

    const identityStore = useIdentityStore();

    if (useLegacyIdentity()) {
      const handleBackButton = () => {
        // we need to handle for dev only when page hot reloads and the state is back to 0
        if (identityStore.queueCounter === 0) {
          identityStore.queueCounter = 1;
        }

        // the popstate event was triggering back button twice
        if (identityStore.queueCounter >= 2 && !hasHitBackButton) {
          hasHitBackButton = true;
          identityStore.updateToPreviousRoute();
        }
      };

      if (to.path.includes('error')) {
        return;
      }

      if (!to.path.includes('identity') && !to.path.includes('payment')) {
        identityStore.resetCounter();
        identityStore.resetSteps();
        if (localStorage.getItem('identityRoutes')) {
          localStorage.removeItem('identityRoutes');
        }

        return;
      }

      if (to.path.includes('identity')) {
        window.addEventListener('popstate', handleBackButton, { once: true });
        hasHitBackButton = false;
      }
    } else if (!to.path.includes('identity') && !to.path.includes('payment')) {
        identityStore.resetIdentityProgress();
    }
  };

  const resetLoadingButtonState = () => {
    useLoadingStateAction(false);
  };

  return {
    handleSuccessModal,
    handleIdentityKycPathings,
    resetLoadingButtonState,
  };
}
